import http from '@/http'

export function get(params) {
  return http.get('/user', {
    loading: true,
    params
  })
}

export function put(data) {
  return data.id ? http.put('/user/' + data.id, data) : http.post('/user', data)
}

export function plugin(data) {
  return http.post('/user/plugin/' + data.id, data)
}