<template>
  <Table :columns="columns" :data="list">
    <template slot-scope="{ row }" slot="name">
      {{ row.name }}<br>{{ row.number }}
    </template>
    <template slot-scope="{ row }" slot="status">
      <Badge :status="row.status ? 'processing' : 'error'" :text="$t('name.user.status_text')[row.status]" />
    </template>

    <template slot-scope="{ row }" slot="action">
      <a @click="$emit('update', row)">{{ $t('name.update') }}</a>
      <Divider type="vertical" />
      <a @click="$emit('checkout', row)">{{ $t('name.user_checkout.name') }}</a>
    </template>
    
    <template slot-scope="{ row }" slot="permission">
      <Tag v-for="item in row.permission" type="border" color="primary" :key="item">{{ item }}</Tag>
    </template>
    
  </Table>
</template>
<script>
export default {
  props: {
    list: Array,
  },
  data() {
    return {
      columns: [
        {
          title: "ID",
          key: "id",
          width: 100
        },
        {
          title: this.$t("name.user.name"),
          slot: "name",
        },
        {
          title: this.$t("name.user.phone"),
          key: 'phone',
        },
        {
          title: this.$t("name.user.email"),
          key: 'email',
        },
        {
          title: this.$t("name.user.tax"),
          key: "tax",
        },
        {
          title: this.$t("name.user.status"),
          slot: 'status',
          align: 'center'
        },
        {
          title: this.$t("name.user.stop_at"),
          key: 'stop_at'
        },
        {
          title: this.$t("name.user.permission"),
          slot: 'permission'
        },
        {
          title: this.$t("name.active"),
          slot: 'action',
          width: 220,
          fixed: 'right'
        },
      ],
    };
  },
};
</script>