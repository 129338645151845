import http from '@/http'

export function get(params) {
  return http.get('/user_checkout', {
    params
  })
}

export function post(data) {
  return http.post('/user_checkout', data)
}

export function del(data) {
  return http.delete('/user_checkout/' + data.id)
}

export function logout(data) {
  return http.get('/user_checkout/logout/' + data.id)
}