import http from '@/http'

export function get() {
  return http.get('/news', {
    loading: true
  })
}

export function put(data) {
  return data.id ? http.put('/news/' + data.id, data) : http.post('/news', data)
}

export function del(id) {
  return http.delete('/news/' + id)
}