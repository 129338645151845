import http from '@/http'

export function get() {
  return http.get('/admin', {
    loading: true
  })
}

export function put(data) {
  return data.id ? http.put('/admin/' + data.id, data) : http.post('/admin', data)
}

export function del(id) {
  return http.delete('/admin/' + id)
}