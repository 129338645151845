<template>
  <div>
    <div class="page-content">
      <div class="page-box">
        <div class="page-box-content">
          <Button type="primary" icon="md-add" @click="onForm(false)">{{ $t('name.create') }}</Button>
        </div>
        <div class="page-box-content">
          <Table :list="list" @delete="onDelete" @update="onForm" />
        </div>
      </div>
    </div>
    <Form ref="form" @get="getData" />
  </div>
</template>
<script>
import Table from './table'
import Form from './form'
import { get, del } from '@/api/admin';

export default {
  components:{
    Table,
    Form
  },
  data() {
    return {
      list: []
    }
  },
  created() {
    this.getData()
  },
  methods: {
    // 获取最新配置
    getData() {
      get().then(res => {
        this.list = res
      })
    },

    // 删除
    onDelete(data) {
      del(data.id).then(res => {
        this.getData()
        this.$Message.success(this.$t('message.' + res.message))
      }).catch(res => {
        this.$Message.error(this.$t('message.error.' + res.message))
      })
    },

    // 打开表单
    onForm(data) {
      this.$refs.form.open(data)
    }
  },
};
</script>