<template>
  <div>
    <div class="page-content">
      <div class="page-box">
        <div class="page-box-content">
          <Row type="flex" justify="space-between">
            <Col>
              <i-form inline>
                <FormItem>
                  <Select v-model="where.status">
                    <Option v-for="(item, key) in $t('name.user.status_text')" :value="key" :key="key">{{ item }}</Option>
                  </Select>
                </FormItem>
                <FormItem>
                  <Input v-model="where.name" :placeholder="$t('name.user.name')" />
                </FormItem>
                <FormItem>
                  <Input v-model="where.phone" :placeholder="$t('name.user.phone')" />
                </FormItem>
                <FormItem>
                  <Button type="primary" icon="md-search" @click="search">{{ $t('name.search') }}</Button>
                </FormItem>
              </i-form>
            </Col>
            <Col>
              <Button type="primary" icon="md-add" @click="onForm(false)">{{ $t('name.create') }}</Button>
            </Col>
          </Row>
          <Table :list="list" @update="onForm" @checkout="onCheckout" />
          <div class="page-box-content" style="text-align:center">
            <Page 
              :current.sync="where.page" 
              :total="total" 
              :page-size="size" 
              @on-change="getData" 
              show-total 
              show-elevator 
            />
          </div>
        </div>
      </div>
    </div>
    <Form ref="form" @get="getData" />
    <Checkout ref="checkout" />
  </div>
</template>
<script>
import Table from './table'
import Form from './form'
import Checkout from './checkout'
import { get } from '@/api/user';

export default {
  components:{
    Table,
    Form,
    Checkout
  },
  data() {
    return {
      list: [],
      total: 0,
      size: 0,
      where: {
        page: 1,
        name: null,
        phone: null,
        status: null
      }
    }
  },
  created() {
    this.getData()
  },
  methods: {
    // 获取最新配置
    getData() {
      get(this.where).then(res => {
        this.list = res.data
        this.where.page = res.current_page
        this.total = res.total
        this.size = res.per_page
      })
    },
    
    // 搜索
    search() {
      this.where.page = 1
      this.getData()
    },

    // 打开表单
    onForm(data) {
      this.$refs.form.open(data)
    },
    
    // 收银台
    onCheckout(data) {
      this.$refs.checkout.open(data)
    }
  },
};
</script>