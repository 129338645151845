<template>
  <div class="page-content">
    <Row :gutter="30">
      <Col span="6">
        <Card :bordered="false">
            <p slot="title">{{ $t('name.index.plugin') }}</p>
            <p class="scoped-tongji">{{ tongji.plugin }}</p>
        </Card>
      </Col>
      <Col span="6">
        <Card :bordered="false">
            <p slot="title">{{ $t('name.index.user') }}</p>
            <p class="scoped-tongji">{{ tongji.user }}</p>
        </Card>
      </Col>
      <Col span="6">
        <Card :bordered="false">
            <p slot="title">{{ $t('name.index.user_ok') }}</p>
            <p class="scoped-tongji">{{ tongji.user_ok }}</p>
        </Card>
      </Col>
      <Col span="6">
        <Card :bordered="false">
            <p slot="title">{{ $t('name.index.user_no') }}</p>
            <p class="scoped-tongji">{{ tongji.user_no }}</p>
        </Card>
      </Col>
    </Row>
  </div>
</template>
<script>
import { index } from '@/api/index';

export default {
  data() {
    return {
      tongji: {}
    }
  },
  created() {
    this.getData()
  },
  methods: {
    // 获取最新配置
    getData() {
      index().then(res => {
        this.tongji = res
      })
    }
  },
};
</script>

<style lang="less" scoped>
  .scoped-tongji {
      font-size: 30px;
  }
</style>