module.exports = {
  create: '创建',
  update: '编辑',
  delete: '删除',
  delete_confirm: '确认是否删除？',
  search: '搜索',
  active: '操作',
  
  index: {
    plugin: '插件数量',
    user: '总用户数',
    user_no: '禁用用户',
    user_ok: '正常用户'
  },

  admin: {
    username: '登陆帐号',
    password: '登陆密码'
  },
  
  permission: {
    name: '名称',
    id: '标识',
    content: '描述',
    sort: '排序',  
  },

  plugin: {
    name: '名称',
    permission: '所属权限',
    img: '图标',
    url: '地址',
    sort: '排序'
  },
  
  template: {
    name: '名称',
    image: '封面',
    html: '源码',
    sort: '排序',
    keyword: '关键字',
    data: "参数",
    version: '版本'
  },
  
  user: {
    name: '名称',
    number: '编号',
    phone: '电话',
    email: '邮箱',
    password: '密码',
    country: '国家',
    city: '城市',
    area: '地区',
    address: '详细地址',
    zip: '邮编',
    bank: '银行名称',
    bank_name: '开户行',
    bank_user: '银行帐号',
    tax: '税号',
    content: '备注',
    stop_at: '截止日期',
    status: '登陆状态',
    status_text: ['禁止', '正常'], 
    tabs: ['基础信息', '银行信息', '插件权限'],
    plugin: '插件',
    permission: '权限'
  },
  
  user_checkout: {
    name: '收银台',
    account: '操作员',
    status: '状态',
    status_text: ['禁止', '开通'],
    token: '绑定',
    token_status: ['已绑定', '未绑定'],
    active_logout: '解绑',
    active_logout_confirm: '解绑后将登出操作员'
  },
  
  news: {
    title: '标题',
    content: '内容'
  },
  
  version: {
    version: '版本号',
    content: '更新内容'
  }
}