import http from '@/http'

export function get() {
  return http.get('/permission', {
    loading: true
  })
}

export function post(data) {
  return http.post('/permission', data)
}
