<template>
  <Modal v-model="modal" :title="$t('name.user_checkout.name')" width="1000">
    <i-form ref="form" :model="form" :rules="rule" inline>
      <FormItem prop="name">
        <Input v-model="form.name" :placeholder="$t('name.user_checkout.name')" />
      </FormItem>
      <FormItem>
        <Button type="primary" :loading="loading" @click="onPost">{{ $t('name.create')  }}</Button>
      </FormItem>
    </i-form>
    <Table :columns="columns" :data="list">
      <template slot-scope="{ row }" slot="account">
        <a v-if="row.account">{{ row.account.name }}</a>
      </template>
      <template slot-scope="{ row }" slot="token">
        <Tag v-if="row.token" type="border" color="success">{{ $t('name.user_checkout.token_status')[0] }}</Tag>
        <Tag v-else type="border" color="warning">{{ $t('name.user_checkout.token_status')[1] }}</Tag>
      </template>
      <template slot-scope="{ row }" slot="status">
        <Badge :status="row.status ? 'processing' : 'error'" :text="$t('name.user_checkout.status_text')[row.status]" />
      </template>
      <template slot-scope="{ row }" slot="action">
        <Poptip
          confirm
          transfer
          :title="$t('name.user_checkout.active_logout_confirm')"
          @on-ok="onLogout(row)"
        >
          <a>{{ $t('name.user_checkout.active_logout') }}</a>
        </Poptip>
        <Divider type="vertical" />
        <Poptip
          confirm
          transfer
          :title="$t('name.delete_confirm')"
          @on-ok="onDelete(row)"
        >
          <a>{{ $t('name.delete') }}</a>
        </Poptip>
      </template>
    </Table>
  </Modal>
</template>
<script>
import { get, post, del, logout } from '@/api/user_checkout'

export default {
  data() {
    return {
      modal: false,
      loading: false,
      list: [],
      form: {},
      field: {
        name: '',
        status: 1
      },
      rule: {
        name: [
          { required: true, message: ' ', trigger: 'blur' }
        ]
      },
      columns: [
        {
          title: "ID",
          key: "id",
          width: 100
        },
        {
          title: this.$t("name.user_checkout.name"),
          key: 'name'
        },
        {
          title: this.$t("name.user_checkout.account"),
          slot: 'account',
        },
        {
          title: this.$t("name.user_checkout.token"),
          slot: 'token',
        },
        {
          title: this.$t("name.user_checkout.status"),
          slot: 'status',
        },
        {
          title: this.$t("name.active"),
          slot: 'action',
          width: 200
        },
      ],
    }
  },
  methods: {
    open(data) {
      this.modal = true

      // 深拷贝
      this.form = Object.assign({ user_id: data.id }, this.field)

      // 清空验证
      this.$refs.form.resetFields()
      
      // 获取列表
      this.getData()
    },
    // 获取
    getData() {
      get(this.form).then(res => {
        this.list = res
      })
    },
    onPost() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          post(this.form).then(res => {
            this.loading = false
            this.$Message.success(this.$t('message.' + res.message))
            this.getData()
          }).catch(res => {
            this.loading = false
            this.$Message.error(this.$t('message.error.' + res.message))
          })
        }
      })
    },
    onDelete(data) {
      del(data).then(res => {
        this.$Message.success(this.$t('message.' + res.message))
        this.getData()
      }).catch(res => {
        this.$Message.error(this.$t('message.error.' + res.message))
      })
    },
    onLogout(data) {
      logout(data).then(res => {
        this.$Message.success(this.$t('message.' + res.message))
        this.getData()
      }).catch(res => {
        this.$Message.error(this.$t('message.error.' + res.message))
      })
    }
  }
}
</script>