<template>
  <Table :columns="columns" :data="list">
    <template slot-scope="{ row }" slot="action">
      <a @click="$emit('update', row)">{{ $t('name.update') }}</a>
      <Divider type="vertical" />
      <Poptip
        confirm
        :title="$t('name.delete_confirm')"
        @on-ok="$emit('delete', row)"
      >
        <a>{{ $t('name.delete') }}</a>
      </Poptip>
    </template>
  </Table>
</template>
<script>
export default {
  props: {
    list: Array,
  },
  data() {
    return {
      columns: [
        {
          title: "ID",
          key: "id",
        },
        {
          title: this.$t("name.version.version"),
          key: "version",
        },
        {
          title: this.$t("name.version.content"),
          key: "content",
        },
        {
          title: this.$t("name.active"),
          slot: 'action',
        },
      ],
    };
  },
};
</script>