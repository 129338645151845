<template>
  <Modal v-model="modal" :title="title" width="350">
    <Form ref="form" :model="form" :rules="rule" :label-width="100">
      <FormItem :label="$t('name.admin.username')" prop="username">
        <Input v-model="form.username" />
      </FormItem>
      <FormItem :label="$t('name.admin.password')" prop="password">
        <Input v-model="form.password" type="password" />
      </FormItem>
    </Form>
    <div slot="footer">
      <Button type="primary" :loading="loading" @click="onPost">{{ title }}</Button>
    </div>
  </Modal>
</template>
<script>
import { put } from '@/api/admin'

export default {
  computed: {
    title() {
      return this.form.id ? this.$t('name.update') : this.$t('name.create') 
    }
  },
  data() {
    return {
      modal: false,
      loading: false,
      form: {},
      field: {
        username: '',
        password: ''
      },
      rule: {
        username: [
          { required: true, message: ' ', trigger: 'blur' }
        ]
      },
    }
  },
  methods: {
    open(data) {
      this.modal = true

      if(!data) {
        // 初始化字段
        data = Object.assign({}, this.field)
      }
      // 深拷贝
      this.form = Object.assign({}, data)

      // 清空验证
      this.$refs.form.resetFields()
    },
    close() {
      this.modal = false
    },
    onPost() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          put(this.form).then(res => {
            this.close()
            this.loading = false
            this.$Message.success(this.$t('message.' + res.message))
            this.$emit('get')
          }).catch(res => {
            this.loading = false
            this.$Message.error(this.$t('message.error.' + res.message))
          })
        }
      })
    }
  }
}
</script>