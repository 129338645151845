<template>
  <div>
    <div class="page-content">
      <div class="page-box">
        <div class="page-box-content">
          <Button type="primary" icon="md-add" @click="onForm(false)">{{ $t('name.create') }}</Button>
        </div>
        <div class="page-box-content">
          <Table :list="list" @update="onForm"  @delete="onDelete" />
          <div class="page-box-content" style="text-align:center">
            <Page 
              :current.sync="where.page" 
              :total="total" 
              :page-size="size" 
              @on-change="getData" 
              show-total 
              show-elevator 
            />
          </div>
        </div>
      </div>
    </div>
    <Form ref="form" @get="getData" />
  </div>
</template>
<script>
import Table from './table'
import Form from './form'
import { get, del } from '@/api/version';

export default {
  components:{
    Table,
    Form
  },
  data() {
    return {
      list: [],
      total: 0,
      size: 0,
      where: {
        page: 1
      }
    }
  },
  created() {
    this.getData()
  },
  methods: {
    // 获取最新配置
    getData() {
      get(this.where).then(res => {
        this.list = res.data
        this.where.page = res.current_page
        this.total = res.total
        this.size = res.per_page
      })
    },

    // 打开表单
    onForm(data) {
      this.$refs.form.open(data)
    },
    
    // 删除
    onDelete(data) {
      del(data.id).then(res => {
        this.getData()
        this.$Message.success(this.$t('message.' + res.message))
      }).catch(res => {
        this.$Message.error(this.$t('message.error.' + res.message))
      })
    },
  },
};
</script>