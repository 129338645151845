module.exports = {
  login: '帐号或密码错误',
  create: '创建成功',
  update: '更新成功',
  delete: '删除成功',
  ok: '操作成功',
  
  error: {
    create: '创建失败',
    update: '更新失败',
    delete: '删除失败',
    error: '操作失败'
  }
}