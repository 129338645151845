<template>
  <Table :columns="columns" :data="list">
    <template slot-scope="{ row }" slot="img">
      <Avatar shape="square" :src="row.img | url" :size="30" />
    </template>
    <template slot-scope="{ row }" slot="action">
      <a @click="$emit('update', row)">{{ $t('name.update') }}</a>
    </template>
  </Table>
</template>
<script>
export default {
  props: {
    list: Array,
  },
  data() {
    return {
      columns: [
        {
          title: "ID",
          key: "id",
        },
        {
          title: this.$t('name.plugin.permission'),
          key: 'permission'
        },
        {
          title: this.$t("name.plugin.img"),
          slot: "img",
        },
        {
          title: this.$t("name.plugin.name"),
          key: "name",
        },
        {
          title: this.$t("name.plugin.url"),
          key: "url",
        },
        {
          title: this.$t("name.plugin.sort"),
          key: "sort",
        },
        {
          title: this.$t("name.active"),
          slot: 'action',
        },
      ],
    };
  },
};
</script>