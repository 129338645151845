import http from '@/http'

export function get(params) {
  return http.get('/version', {
    loading: true,
    params
  })
}

export function put(data) {
  return data.id ? http.put('/version/' + data.id, data) : http.post('/version', data)
}

export function del(id) {
  return http.delete('/version/' + id)
}