<template>
  <Modal v-model="modal" :title="$t('name.update')" width="700">
    <Form ref="form" :model="form" :rules="rule" :label-width="60">
      <FormItem :label="$t('name.permission.id')" prop="id">
        <Input v-model="form.id" />
      </FormItem>
      <FormItem :label="$t('name.permission.name')" prop="name">
        <Input v-model="form.name" />
      </FormItem>
      <FormItem :label="$t('name.permission.content')" prop="content">
        <Input v-model="form.content" />
      </FormItem>
      <FormItem :label="$t('name.permission.sort')" prop="sort">
        <InputNumber v-model="form.sort" />
      </FormItem>
    </Form>
    <div slot="footer">
      <Button type="primary" :loading="loading" @click="onPost">{{ $t('name.update') }}</Button>
    </div>
  </Modal>
</template>
<script>
import { post } from '@/api/permission'

export default {
  data() {
    return {
      modal: false,
      loading: false,
      form: {},
      field: {
        name: '',
        id: '',
        content: '',
        sort: 0
      },
      rule: {
        name: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        id: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        content: [
          { required: true, message: ' ', trigger: 'blur' }
        ]
      },
    }
  },
  methods: {
    open(data) {
      this.modal = true

      if(!data) {
        // 初始化字段
        data = Object.assign({}, this.field)
      }
      // 深拷贝
      this.form = Object.assign({}, data)

      // 清空验证
      this.$refs.form.resetFields()
    },
    close() {
      this.modal = false
    },
    onPost() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          post(this.form).then(res => {
            this.close()
            this.loading = false
            this.$Message.success(this.$t('message.' + res.message))
            this.$emit('get')
          }).catch(res => {
            this.loading = false
            this.$Message.error(this.$t('message.error.' + res.message))
          })
        }
      })
    }
  }
}
</script>