<template>
  <Table :columns="columns" :data="list">
    <template slot-scope="{ row }" slot="action">
      <a @click="$emit('update', row)">{{ $t('name.update') }}</a>
    </template>
  </Table>
</template>
<script>
export default {
  props: {
    list: Array,
  },
  data() {
    return {
      columns: [
        {
          title: this.$t("name.permission.id"),
          key: "id",
        },
        {
          title: this.$t("name.permission.name"),
          key: "name",
        },
        {
          title: this.$t("name.permission.content"),
          key: "content",
        },
        {
          title: this.$t("name.permission.sort"),
          key: "sort",
        },
        {
          title: this.$t("name.active"),
          slot: 'action',
        },
      ],
    };
  },
};
</script>