<template>
  <Modal v-model="modal" :title="title" width="700">
    <Form ref="form" :model="form" :rules="rule" label-position="top">
      <Tabs v-model="tabs">
        <TabPane :label="$t('name.user.tabs.0')" name="0">
          <div class="page-box-content">
            <Row :gutter="20">
              <Col span="12">
                <FormItem :label="$t('name.user.name')" prop="name">
                  <Input v-model="form.name" />
                </FormItem>
              </Col>
              <Col span="12">
                <FormItem :label="$t('name.user.number')" prop="number">
                  <Input v-model="form.number" />
                </FormItem>
              </Col>
            </Row>
            <Row :gutter="20">
              <Col span="8">
                <FormItem :label="$t('name.user.phone')" prop="phone">
                  <Input v-model="form.phone" />
                </FormItem>
              </Col>
              <Col span="8">
                <FormItem :label="$t('name.user.email')" prop="email">
                  <Input v-model="form.email" />
                </FormItem>
              </Col>
              <Col span="8">
                <FormItem :label="$t('name.user.password')" prop="password">
                  <Input v-model="form.password"/>
                </FormItem>
              </Col>
            </Row>
            <Row :gutter="20">
              <Col span="8">
                <FormItem :label="$t('name.user.country')" prop="country">
                  <Select v-model="form.country">
                    <Option v-for="item in country" :value="item.name" :key="item.id">{{ item.name }}</Option>
                  </Select>
                </FormItem>
              </Col>
              <Col span="8">
                <FormItem :label="$t('name.user.city')" prop="city">
                  <Input v-model="form.city"/>
                </FormItem>
              </Col>
              <Col span="8">
                <FormItem :label="$t('name.user.area')" prop="area">
                  <Input v-model="form.area"/>
                </FormItem>
              </Col>
            </Row>
            <Row :gutter="20">
              <Col span="16">
                <FormItem :label="$t('name.user.address')" prop="address">
                  <Input v-model="form.address"/>
                </FormItem>
              </Col>
              <Col span="8">
                <FormItem :label="$t('name.user.zip')" prop="zip">
                  <Input v-model="form.zip"/>
                </FormItem>
              </Col>
            </Row>
            <Row :gutter="20">
              <Col :span="16">
                <FormItem :label="$t('name.user.status')" prop="status">
                  <i-switch  v-model="form.status" :true-value="1" :false-value="0" />
                </FormItem>
              </Col>
              <Col :span="8">
                <FormItem :label="$t('name.user.stop_at')" prop="stop_at">
                  <DatePicker :value="form.stop_at" format="dd/MM/yyyy" type="date" placement="top" @on-change="onDate" />
                </FormItem>
              </Col>
            </Row>
          </div>
        </TabPane>
        <TabPane :label="$t('name.user.tabs.1')" name="1">
          <div class="page-box-content">
            <Row :gutter="20">
              <Col :span="12">
                <FormItem :label="$t('name.user.bank')" prop="bank">
                  <Input v-model="form.bank"/>
                </FormItem>
              </Col>
              <Col :span="12">
                <FormItem :label="$t('name.user.bank_name')" prop="bank_name">
                  <Input v-model="form.bank_name"/>
                </FormItem>
              </Col>
            </Row>
            <FormItem :label="$t('name.user.bank_user')" prop="bank_user">
              <Input v-model="form.bank_user"/>
            </FormItem>
            <FormItem :label="$t('name.user.tax')" prop="tax">
              <Input v-model="form.tax"/>
            </FormItem>
            <FormItem :label="$t('name.user.content')" prop="content">
              <Input v-model="form.content" type="textarea" :rows="4"/>
            </FormItem>
          </div>
        </TabPane>
        <TabPane :label="$t('name.user.tabs.2')" name="2">
          <div class="page-box-content">
            <FormItem :label="$t('name.user.permission')">
              <CheckboxGroup v-model="form.permission">
                <Row :gutter="20">
                  <Col :span="6" v-for="item in permission" :key="item.id">
                    <Checkbox :label="item.id">{{ item.name }}</Checkbox>
                  </Col>
                </Row>
              </CheckboxGroup>
            </FormItem>
            <FormItem :label="$t('name.user.plugin')">
              <CheckboxGroup v-model="form.plugin">
                <Row :gutter="20">
                  <Col :span="8" v-for="item in plugin" :key="item.id">
                    <Checkbox :label="item.id">{{ item.name }}({{ item.permission }})</Checkbox>
                  </Col>
                </Row>
              </CheckboxGroup>
            </FormItem>
          </div>
        </TabPane>
      </Tabs>
    </Form>
    <div slot="footer">
      <Button type="primary" :loading="loading" @click="onPost">{{ title }}</Button>
    </div>
  </Modal>
</template>
<script>
import { put } from '@/api/user'
import { country } from '@/api/country'
import { get as getPlugin } from '@/api/plugin'
import { get as getPermission } from '@/api/permission'

export default {
  computed: {
    title() {
      return this.form.id ? this.$t('name.update') : this.$t('name.create') 
    }
  },
  data() {
    return {
      modal: false,
      loading: false,
      tabs: '0',
      country: [],
      form: {},
      field: {
        name: '',
        number: '',
        phone: '',
        email: '',
        password: '',
        country: '',
        city: '',
        area: '',
        address: '',
        zip: '',
        bank: '',
        bank_user: '',
        tax: '',
        content: '',
        plugin: [],
        permission: [],
        stop_at: '',
        status: 0
      },
      rule: {
        name: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        number: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        email: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        country: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        city: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        area: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        address: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        zip: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        stop_at: [
          { required: true, message: ' ', trigger: 'blur' }
        ]
      },
    }
  },
  created() {
    // 请求地区
    country().then(res => {
      this.country = res
    })
    // 请求插件
    getPlugin().then(res => {
      this.plugin = res
    })
    // 请求权限
    getPermission().then(res => {
      this.permission = res
    })
  },
  methods: {
    open(data) {
      this.modal = true

      if(!data) {
        // 初始化字段
        data = Object.assign({}, this.field)
      }
      // 深拷贝
      this.form = Object.assign({}, data)

      // 清空验证
      this.$refs.form.resetFields()
      
      // 切换标签页
      this.tabs = '0'
    },
    close() {
      this.modal = false
    },
    onPost() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          put(this.form).then(res => {
            this.close()
            this.loading = false
            this.$Message.success(this.$t('message.' + res.message))
            this.$emit('get')
          }).catch(res => {
            this.loading = false
            this.$Message.error(this.$t('message.error.' + res.message))
          })
        }
      })
    },
    onDate(date) {
      this.form.stop_at = date
    }
  }
}
</script>