import http from '@/http'

export function get() {
  return http.get('/plugin', {
    loading: true
  })
}

export function put(data) {
  return data.id ? http.put('/plugin/' + data.id, data) : http.post('/plugin', data)
}
