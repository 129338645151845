module.exports = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/view/home'),
    meta: {
      title: 'menu.home',
      menu: 'home'
    },
  },
  {
    path: 'admin',
    component: () => import('@/view/admin'),
    meta: {
      title: 'menu.admin',
      menu: 'admin'
    }
  },
  {
    path: 'permission',
    component: () => import('@/view/permission'),
    meta: {
      title: 'menu.permission',
      menu: 'permission'
    }
  },
  {
    path: 'plugin',
    component: () => import('@/view/plugin'),
    meta: {
      title: 'menu.plugin',
      menu: 'plugin'
    }
  },
  {
    path: 'template',
    component: () => import('@/view/template'),
    meta: {
      title: 'menu.template',
      menu: 'template'
    }
  },
  {
    path: 'user',
    component: () => import('@/view/user'),
    meta: {
      title: 'menu.user',
      menu: 'user'
    }
  },
  {
    path: 'news',
    component: () => import('@/view/news'),
    meta: {
      title: 'menu.news',
      menu: 'news'
    }
  },
  {
    path: 'version',
    component: () => import('@/view/version'),
    meta: {
      title: 'menu.version',
      menu: 'version'
    }
  }
]