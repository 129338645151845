import http from '@/http'

export function get() {
  return http.get('/template', {
    loading: true
  })
}

export function put(data) {
  return data.id ? http.put('/template/' + data.id, data) : http.post('/template', data)
}

export function del(id) {
  return http.delete('/template/' + id)
}