<template>
  <div>
    <MenuItem name="home" to="/main/"><Icon type="md-home" />{{ $t('menu.home') }}</MenuItem>
    <MenuItem name="admin" to="/main/admin"><Icon type="md-contact" />{{ $t('menu.admin') }}</MenuItem>
    <MenuItem name="user" to="/main/user"><Icon type="ios-people" />{{ $t('menu.user') }}</MenuItem>
    <MenuItem name="permission" to="/main/permission"><Icon type="md-apps" />{{ $t('menu.permission') }}</MenuItem>
    <MenuItem name="plugin" to="/main/plugin"><Icon type="md-apps" />{{ $t('menu.plugin') }}</MenuItem>
    <MenuItem name="template" to="/main/template"><Icon type="md-apps" />{{ $t('menu.template') }}</MenuItem>
    <MenuItem name="news" to="/main/news"><Icon type="md-albums" />{{ $t('menu.news') }}</MenuItem>
    <MenuItem name="version" to="/main/version"><Icon type="logo-buffer" />{{ $t('menu.version') }}</MenuItem>
  </div>
</template>

<script>
export default {
  props: {
    update: Function
  }
}
</script>

<style lang="less" scoped>
  .scoped-right{
    float:right;
    
    &.x{
      margin-right: 15px
    }

    &.m{
      margin-right: 25px
    }
  }

</style>