module.exports = {
  main: '首页',
  home: '概览',
  user: '用户管理',
  permission: '权限管理',
  plugin: '插件管理',
  template: '模版管理',
  admin: '管理员',
  news: '新闻管理',
  version: '版本管理'
}